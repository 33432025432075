@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  #__next > *,
  #root > * {
    @apply w-full;
  }

  html {
    scroll-behavior: smooth;
  }

  input[type='number']::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
  }

  :focus-visible {
    outline: none;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0; /* Removes leftover margin */
  }

  ::-moz-selection {
    @apply bg-black text-white;
  }

  ::selection {
    @apply bg-black text-white;
  }

  .hide-scrollbar {
    @apply overflow-auto;
    -ms-overflow-style: none; /* IE 11 */
    scrollbar-width: none; /* Firefox 64 */
  }

  .hide-scrollbar::-webkit-scrollbar {
    @apply hidden;
  }

  img {
    @apply text-transparent;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .input-outlined input:focus-within ~ label {
    @apply text-blue-3;
  }

  .input-outlined input:focus-within ~ label,
  .input-outlined input:not(:placeholder-shown) ~ label {
    @apply transform scale-75 -top-[11px] z-0 px-1 ml-2 bg-white;
  }

  .select-outlined select:focus-within ~ label,
  .labeled input:focus-within ~ label {
    @apply text-blue-3;
  }

  .select-outlined select:focus-within ~ label,
  .select-outlined select:not(:placeholder-shown) ~ label,
  .labeled input:focus-within ~ label,
  .labeled input:not(:placeholder-shown) ~ label {
    @apply transform scale-75 -top-[11px] z-0 px-1 ml-2 bg-white;
  }

  /* Markdown */
  #md-layout {
    @apply flex flex-col;
  }

  #md-layout strong {
    @apply font-medium;
  }

  #md-layout h1 {
    @apply mt-12 mb-4 text-header-d-1 font-medium mobile:mt-16;
  }

  #md-layout p {
    @apply mb-2;
  }

  #md-layout h2 {
    @apply mt-8 font-medium text-header-2;
  }

  #md-layout h3 {
    @apply mt-8 mb-2 text-header-3 font-medium;
  }

  #md-layout h4 {
    @apply mt-8 mb-1 font-medium text-header-4;
  }

  #md-layout h5 {
    @apply mt-4 mb-1 text-header-5 font-medium;
  }

  #md-layout ul {
    @apply flex flex-col my-2 gap-2 ml-4 list-disc;
  }

  #md-layout table {
    @apply mt-3;
  }

  #md-layout thead {
    @apply border-y border-gray-6;
  }

  #md-layout th {
    @apply py-2 px-1 text-body-3 font-normal text-left text-gray-4 select-none;
  }

  #md-layout tbody {
    @apply before:[content:"_"_!important] after:[content:"_"_!important] before:block after:block before:h-3 after:h-3;
  }

  #md-layout td {
    @apply px-2 py-2;
  }

  #md-layout tr {
    @apply h-10 duration-200 transition-colors border-y border-gray-8;
  }

  #md-layout a {
    @apply font-medium hover:text-blue-3 transition-colors duration-200;
  }

  /* Markdown (Mobile App Pages) */

  #md-layout-app {
    @apply flex flex-col text-[black];
  }

  #md-layout-app h1 {
    @apply mt-6 mb-4 text-[26px] leading-[32px] font-medium mobile:mt-6;
  }

  #md-layout-app h2,
  #md-layout-app h3 {
    @apply mt-6 mb-2 text-header-4 font-medium;
  }

  #md-layout-app h4 {
    @apply mt-8 font-medium text-header-4;
  }

  #md-layout-app h5 {
    @apply mt-4 mb-2 text-header-5 font-medium;
  }

  #md-layout-app ul {
    @apply flex flex-col gap-2 ml-4 list-disc;
  }

  /* Markdown */
  #blog-layout {
    @apply flex flex-col;
  }

  #blog-layout strong {
    @apply font-medium;
  }

  #blog-layout ul,
  #blog-layout ol {
    @apply my-4 flex flex-col gap-8;
  }

  #blog-layout ul li,
  #blog-layout ol li {
    /* @apply ml-6; */
  }

  #blog-layout ol {
    /* @apply [counter-reset:orderedlist]; */
  }

  #blog-layout ol li {
    /* @apply list-decimal; */
    /* @apply grid grid-cols-2 grid-rows-2; */
  }

  #blog-layout ol li::before {
    /* @apply [grid-area:1/1/3/2] [counter-increment:orderedlist] mb-auto [content:counter(orderedlist)_!important] rounded-full border border-gray-6 w-6 h-6 inline-flex items-center justify-center; */
  }

  #blog-layout p {
    @apply mb-4;
  }

  #blog-layout h1,
  #blog-layout h2,
  #blog-layout h3,
  #blog-layout h4,
  #blog-layout h5,
  #blog-layout h6 {
    @apply mt-8 mb-3 font-medium text-header-3;
  }

  #blog-layout table {
    @apply mt-3;
  }

  #blog-layout thead {
    @apply border-y border-gray-6;
  }

  #blog-layout hr {
    @apply pb-4 text-gray-6;
  }

  #blog-layout th {
    @apply py-2 px-1 text-body-3 font-normal text-left text-gray-4 select-none;
  }

  #blog-layout tbody {
    @apply before:[content:"_"_!important] after:[content:"_"_!important] before:block after:block before:h-3 after:h-3;
  }

  #blog-layout td {
    @apply px-2 py-2;
  }

  #blog-layout tr {
    @apply h-10 duration-200 transition-colors border-y border-gray-8;
  }

  #blog-layout a {
    @apply text-blue-3;
  }
}
